// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDs3gO_A8cvlK529Fgo0hwsPJLZpEuimG8",
    authDomain: "ivesmedicare-9f958.firebaseapp.com",
    projectId: "ivesmedicare-9f958",
    storageBucket: "ivesmedicare-9f958.appspot.com",
    messagingSenderId: "649443494008",
    appId: "1:649443494008:web:9392a05c5c2c79bc74458d",
    measurementId: "G-5VYTXXP7PC"
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
