import { Injectable } from '@angular/core';
import { Firestore , doc, getDoc, docData, query, collection, orderBy, collectionData, where, updateDoc, arrayUnion, arrayRemove, setDoc} from '@angular/fire/firestore';
import { from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public afs :  Firestore) { }
  
  getWebsite(){
    const docRef =  doc(this.afs, 'website', 'data')
    
    return  docData(docRef)
  }

  getCollection(){
    const colRef = query (collection(this.afs, 'website', 'data', 'teams') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  getLoans(){
    const colRef = query (collection(this.afs, 'website', 'data', 'loans') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }


  getHomeSlider(){
    const colRef = query(collection(this.afs, 'website', 'data', 'background') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  getCareers(){
    const colRef = query(collection(this.afs, 'website', 'data', 'careers') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  getBlogs(){
    const colRef = query(collection(this.afs, 'blogs') , orderBy('date', 'desc'))
    return collectionData(colRef)
  }

getAllPlans(entity){
  const colRef = query(collection(this.afs, "plans"), where('entity','==', entity))
  return collectionData(colRef)
}

getEntirePlans(){
  const colRef = query(collection(this.afs, "plans"))
  return collectionData(colRef)
}

getAllHospitals(){
  const colRef = query(collection(this.afs, "hospital"),orderBy('label', "asc"))
  return collectionData(colRef)
}

async toggleHospitals(id, status){ 
const docRef = doc(this.afs, "hospital", `${id}`);

await updateDoc(docRef, {
  enable: status
});
 

}


async addPlan(id, plan) {
  const docRef = doc(this.afs, "hospital", `${id}`);
  
  await updateDoc(docRef, {
      plans: arrayUnion(plan)
  });
  

}

async removePlan(id, plan) {
  const docRef = doc(this.afs, "hospital", `${id}`);
  
  await updateDoc(docRef, {
    plans: arrayRemove(plan)
});
}

getHospitals(healthPlan){
  const colRef = query(collection(this.afs, "hospital"), where("enable", '==', true), where("plans", 'array-contains', healthPlan),orderBy('label', "asc"))
  return collectionData(colRef)
}
pullDependentOf(uid) {
  const colRef = query(collection(this.afs, `users/${uid}/dependants`))
  return collectionData(colRef)
}

getStates(){
  const colRef = query(collection(this.afs, "states"), orderBy('sn', "asc"))
  return collectionData(colRef)
}

getOrganisations() {
  const colRef = collection(this.afs, "organisations")
  return collectionData(colRef)
}

getCompanyPlans(){
  const colRef = collection(this.afs, "planbenefits")
  return collectionData(colRef)
}

// getSortedCompanyPlans(){
//   const colRef = collection(this.afs, "planbenefits")
//   return collectionData(colRef, orderBy('number', 'asc'))
// }

getRetailPlans(){
  const colRef = collection(this.afs, "retailplans")
  return collectionData(colRef)
}
async getTerms() {
  const docRef = doc(this.afs, 'terms', 'terms');
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return null
  }
}

getVideos(){
  const colRef = query(collection(this.afs, "videos"), orderBy('sn', 'asc'))
  return collectionData(colRef)
}

async addHospital(name) {   
const  hospitalId = doc(collection(this.afs, 'id')).id
await setDoc(doc(this.afs, "hospital", hospitalId), {
  enable:  true,
  id: hospitalId,
  label: name, 
  name: name,
  plans: [],
  value: name
  
});


}
}

