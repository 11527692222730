import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'asset',
    loadChildren: () => import('./pages/loans/asset-loan/asset-loan.module').then( m => m.AssetLoanPageModule)
  },
  {
    path: 'beta',
    loadChildren: () => import('./pages/loans/beta-loan/beta-loan.module').then( m => m.BetaLoanPageModule)
  },
  {
    path: 'federal',
    loadChildren: () => import('./pages/loans/federal-loan/federal-loan.module').then( m => m.FederalLoanPageModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./pages/loans/education-loan/education-loan.module').then( m => m.EducationLoanPageModule)
  },
  {
    path: 'salary',
    loadChildren: () => import('./pages/loans/salary-loan/salary-loan.module').then( m => m.SalaryLoanPageModule)
  },
  {
    path: 'small',
    loadChildren: () => import('./pages/loans/small-loan/small-loan.module').then( m => m.SmallLoanPageModule)
  },
  {
    path: 'kaylog',
    loadChildren: () => import('./pages/loans/kaylog-loan/kaylog-loan.module').then( m => m.KaylogLoanPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about-page/about-page.module').then( m => m.AboutPagePageModule)
  },
  {
    path: 'apply',
    loadChildren: () => import('./pages/application-page/application-page.module').then( m => m.ApplicationPagePageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog-page/blog-page.module').then( m => m.BlogPagePageModule)
  },
  {
    path: 'career',
    loadChildren: () => import('./pages/career-page/career-page.module').then( m => m.CareerPagePageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/gallery-page/gallery-page.module').then( m => m.GalleryPagePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/help-page/help-page.module').then( m => m.HelpPagePageModule)
  },
  {
    path: 'testimonial',
    loadChildren: () => import('./pages/testimonial-page/testimonial-page.module').then( m => m.TestimonialPagePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-page/contact-page.module').then( m => m.ContactPagePageModule)
  },
  {
    path: 'blog-details',
    loadChildren: () => import('./pages/blog-details/blog-details.module').then( m => m.BlogDetailsPageModule)
  }, 
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'retailplans',
    loadChildren: () => import('./retailplans/retailplans.module').then( m => m.RetailplansPageModule)
  },
  {
    path: 'companyplans',
    loadChildren: () => import('./companyplans/companyplans.module').then( m => m.CompanyplansPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'addcompany',
    loadChildren: () => import('./addcompany/addcompany.module').then( m => m.AddcompanyPageModule)
  },
  {
    path: 'adddependent',
    loadChildren: () => import('./adddependent/adddependent.module').then( m => m.AdddependentPageModule)
  },
  {
    path: 'showdependents',
    loadChildren: () => import('./showdependents/showdependents.module').then( m => m.ShowdependentsPageModule)
  },
  {
    path: 'addretailplan',
    loadChildren: () => import('./addretailplan/addretailplan.module').then( m => m.AddretailplanPageModule)
  },
  {
    path: 'addcorporateplan',
    loadChildren: () => import('./addcorporateplan/addcorporateplan.module').then( m => m.AddcorporateplanPageModule)
  },
  {
    path: 'addplan',
    loadChildren: () => import('./addplan/addplan.module').then( m => m.AddplanPageModule)
  },
  {
    path: 'addplanretail',
    loadChildren: () => import('./addplanretail/addplanretail.module').then( m => m.AddplanretailPageModule)
  },
  {
    path: 'hospitals',
    loadChildren: () => import('./hospitals/hospitals.module').then( m => m.HospitalsPageModule)
  },
  {
    path: 'addhospital',
    loadChildren: () => import('./addhospital/addhospital.module').then( m => m.AddhospitalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
